import React from 'react';
import {Router} from '@reach/router';
import Login from './SusLogin';
import loadable from '@loadable/component';

const EmailSearch = loadable(() => import('./EmailSearch'));

/**
 *
 *
 * @return {*}
 */
function SusAgent() {
  return (
    <>
      <Router basepath='/sus-agent'>
        <Login path="/login" />
        <EmailSearch path="/email-search" />
      </Router>
    </>
  );
}

export default SusAgent;
